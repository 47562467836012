<template>
  <div class="container">
    <h3>Family Site & Admin</h3>
    <div class="contents_wrap">
      <div class="wrap">
        <div class="box box1">
          <h4>글로벌</h4>
          <div class="list">
            <div class="site line list_b_r_1">
              <a href="https://m.carrotenglish.net/login" target="_blank"
                ><div class="check">랜딩표기</div>
                ITM ELP (학습자단)</a
              >
            </div>
            <div
              class="admin list_b_r_2 line pointer"
              @click="
                emp.moveAdmin(
                  `https://hq.carrotenglish.net/auth/common/directLink?uid=${emp.email}`
                )
              "
            >
              <a>ITM HQ 매니저단</a>
            </div>
            <div
              class="admin list_b_r_2 line pointer"
              @click="emp.moveAdmin(`https://teacher.carrotenglish.net/login`)"
            >
              <a>ITM 강사단 - PC 웹</a>
            </div>
            <div
              class="admin list_b_r_2 line pointer"
              @click="emp.moveAdmin(`https://itmtutorhq.carrotcatcher.com/login?email=${emp.email}&idx=${emp.idx}`)"
            >
              <a>New ITM HQ</a>
            </div>
            <div
              class="admin list_b_r_3 line pointer"
              @click="emp.moveAdmin(`https://itmtutorweb.carrotcatcher.com/`)"
            >
              <a>New ITM App</a>
            </div>

            
          </div>
          <div class="list">
            <div class="site line list_b_r_1">
              <a href="https://langlab.carrotglobal.com" target="_blank"
                ><div class="check">랜딩표기</div>
                랭기지랩 (학습자단)</a
              >
            </div>
            <div
              class="admin list_b_r_2 line pointer"
              @click="
                emp.moveAdmin(
                  ` https://langlab-admin.carrotglobal.com/login?uid=${emp.email}`
                )
              "
            >
              <a>랭기지랩 - HQ 매니저단</a>
            </div>
            <div
              class="admin list_b_r_3 pointer"
              @click="emp.moveAdmin(`https://langlab-tutor.carrotglobal.com`)"
            >
              <a>랭기지랩 - 강사단</a>
            </div>
          </div>
          <div class="list site">
            <a href="http://www.how-writing.com/how_intro" target="_blank"
              ><div class="check">랜딩표기</div>
              HOW</a
            >
          </div>
          <div class="list">
            <div
              class="admin list_b_r_1 line pointer"
              @click="
                emp.moveAdmin(
                  `https://admin.carrotleaders.com/connectionHQ?email=${emp.email}`
                )
              "
            >
              <a>리더십 진단평가 관리자</a>
            </div>
          </div>

          <div class="list">
            <div
              class="admin list_b_r_1 line pointer"
              @click="emp.moveAdmin(`https://carrotbpo.com/apply/login`)"
            >
              <a>삼성전자DS BPO 관리자(삼성)</a>
            </div>
            <div
              class="admin list_b_r_2 line pointer"
              @click="
                emp.moveAdmin(
                  `https://carrotbpo.com/carrot/login?email=${emp.email}`
                )
              "
            >
              <a>삼성전자DS BPO 관리자(캐럿)</a>
            </div>
            <div
              class="admin list_b_r_2 line pointer"
              @click="emp.moveAdmin(`https://staff.carrotbpo.com/staff/login`)"
            >
              <a>삼성전자DS BPO 매니저(캐럿)</a>
            </div>
          </div>
        </div>
        <div class="box box2">
          <h4>에듀테크</h4>
          <div class="list">
            <div class="site line list_b_r_1">
              <a href="https://www.imooc.co.kr/" target="_blank"
                ><div class="check">랜딩표기</div>
                i:MOOC</a
              >
            </div>
            <div
              class="admin list_b_r_2 line pointer"
              @click="
                emp.moveAdmin(
                  `http://admin.imooc.co.kr/rest/auth/login?kname=${emp.kname}&email=${emp.email}&is_intranet=Y`
                )
              "
            >
              <a>i:MOOC 관리자</a>
            </div>
            <div
              class="admin list_b_r_3 pointer"
              @click="
                emp.moveAdmin(
                  `http://admin.imooc.vn/rest/auth/login?kname=${emp.kname}&email=${emp.email}`
                )
              "
            >
              <a>i:MOOC 베트남 관리자</a>
            </div>
          </div>
          <div class="list">
            <div class="site line list_b_r_1">
              <a href="http://aitutoring.co.kr/" target="_blank"
                ><div class="check">랜딩표기</div>
                말랑톡</a
              >
            </div>
            <div
              class="admin list_b_r_3 pointer"
              @click="
                emp.moveAdmin(
                  `https://master.malangtalk.com/connectionHQ?email=${emp.email}`
                )
              "
            >
              <a>말랑톡 관리자 </a>
            </div>
          </div>
          <div class="list">
            <!-- <div class="site line list_b_r_1">
              <a href="http://olivepop.net/" target="_blank"
                ><div class="check">랜딩표기</div>
                올리브팝</a
              >
            </div>
            <div class="admin list_b_r_3 pointer" @click="emp.moveAdmin(`http://hq.olivepop.net/login?id=${emp.email}&idx=${emp.idx}`)">
              <a>올리브팝 관리자</a>
            </div> -->
          </div>
          <div
            class="admin shadow mb pointer"
            @click="
              emp.moveAdmin(
                `https://platform.carrotenglish.net/login?uid=${emp.email}&idx=${emp.idx}`
              )
            "
          >
            <a>당근농장 앱 관리자</a>
          </div>
          <div
            class="admin shadow mb pointer"
            @click="
              emp.moveAdmin(
                `https://itm.carrotenglish.net/rest/mr8/admin_ssologin?email=${emp.email}`
              )
            "
          >
            <a>팔선생 관리자</a>
          </div>
          <div class="admin shadow mb pointer" @click="emp.itsMeAdmin">
            <a>It's Me 스마트러닝 관리자</a>
          </div>

          <div class="list">
            <div class="site line list_b_r_1">
              <a href="https://dic.carrotglobal.com/" target="_blank"
                ><div class="check">랜딩표기</div>
                맞춤표현집</a
              >
            </div>
            <div
              class="admin list_b_r_2 line pointer"
              @click="
                emp.moveAdmin('https://carrotglobal.com/biz_english/down')
              "
            >
              <a>맞춤표현집 앱 다운로드</a>
            </div>
            <div
              class="admin list_b_r_3 pointer"
              @click="emp.moveAdmin('https://expadmin.carrotenglish.net/')"
            >
              <a>맞춤표현집 관리자</a>
            </div>
          </div>
        </div>
        <div class="box box3">
          <h4>전화/화상영어</h4>
          <div class="list">
            <div class="site line list_b_r_1">
              <a href="https://www.carrotenglish.com/" target="_blank"
                ><div class="check">랜딩표기</div>
                전화영어:모바일화상</a
              >
            </div>
            <div
              class="admin list_b_r_2 line pointer"
              @click="
                emp.moveAdmin(
                  `https://ctm.carrotenglish.com/act/outlogin?email=${emp.email}`
                )
              "
            >
              <a>CTM HQ 매니저단</a>
            </div>
            <div class="admin list_b_r_2 line pointer">
              <a
                href="https://tctm.carrotenglish.com/landing/home"
                target="_blank"
                >CTM 강사단</a
              >
            </div>
            <div class="admin list_b_r_3">
              <a
                @click="
                  emp.moveAdmin(
                    `https://mediabook-admin.carrotenglish.kr/connectionHQ?email=${emp.email}`
                  )
                "
                target="_blank"
                >NEW 미디어교재
              </a>
            </div>
          </div>
          <div class="list site">
            <a href="https://carrotjr.com/index?" target="_blank"
              ><div class="check" target="_blank">랜딩표기</div>
              화상영어:주니어</a
            >
          </div>
          <div class="list site">
            <a href="https://carrotedu.com/" target="_blank"
              ><div class="check" target="_blank">랜딩표기</div>
              CARROT EDU</a
            >
          </div>
          <div class="list site">
            <a href="https://carrotchinese.com/" target="_blank"
              ><div class="check" target="_blank">랜딩표기</div>
              당근중국어 랜딩</a
            >
          </div>
          <div class="list site">
            <a href="https://carrotenglishtutor.com/" target="_blank"
              ><div class="check" target="_blank">랜딩표기</div>
              강사채용사이트</a
            >
          </div>
          <div class="list site">
            <a
              href="https://biz.carrotenglish.com/ctm_tutor_app/down/"
              target="_blank"
              ><div class="check" target="_blank">랜딩표기</div>
              CTM 강사 APP</a
            >
          </div>
        </div>
      </div>
      <!-- 2행 -->
      <div class="wrap">
        <div class="box box4">
          <h4>SPAC^</h4>
          <div class="list">
            <div class="site line list_b_r_1">
              <a href="http://www.thespac.net/" target="_blank"
                ><div class="check">랜딩표기</div>
                SPAC^</a
              >
            </div>
            <div
              class="admin list_b_r_2 line pointer"
              @click="
                emp.moveAdmin(
                  `https://admin.thespac.net/connectionHQ?email=${emp.email}`
                )
              "
            >
              <a>New SPAC^ 관리자</a>
            </div>
            <div class="admin list_b_r_2 line pointer">
              <a href="https://admin.thespac.net" target="_blank"
                >New SPAC^ 평가자</a
              >
            </div>
            <div class="admin list_b_r_2 line pointer">
              <a
                :href="`https://admin-writing.thespac.net/rest/common/autoLogin?uid=${emp.email}&type=hq`"
                target="_blank"
                >SPAC^ Wri. 관리자</a
              >
            </div>
            <div class="admin list_b_r_2 line pointer">
              <a
                href="https://rater-writing.thespac.net/admin/login"
                target="_blank"
                >SPAC^ Wri. 평가자</a
              >
            </div>
          </div>
          <div class="list">
            <div class="site line list_b_r_1">
              <a href="http://www.thegcat.com" target="_blank"
                ><div class="check">랜딩표기</div>
                gCAT</a
              >
            </div>
            <div
              class="admin list_b_r_2 line pointer"
              @click="
                emp.moveAdmin(
                  `https://api.thespac.net/c-test2/admin_ok_by_spac.php?spac_id=${emp.email}&testtype=9`
                )
              "
            >
              <a>gCAT 관리자</a>
            </div>
          </div>
          <div class="list">
            <div class="site line list_b_r_1">
              <a href="https://goepta.com/" target="_blank"
                ><div class="check">랜딩표기</div>
                goEPTA</a
              >
            </div>
            <div
              class="admin list_b_r_2 line pointer"
              @click="emp.moveAdmin(`https://goepta.com/admin/`)"
            >
              <a>goEPTA 관리자</a>
            </div>
          </div>
          <div class="list site">
            <a
              :href="`https://old.carrotians.net/mss/_admin/proc/auto_login_proc_mms.php?email=${emp.email}`"
              target="_blank"
              ><div class="check">랜딩표기</div>
              MSS 관리자</a
            >
          </div>
          <div class="list site">
            <a
              :href="`https://api.thespac.net/c-test2/admin_ok_by_spac.php?spac_id=${emp.email}&testtype=6`"
              target="_blank"
              ><div class="check">랜딩표기</div>
              Enneagram</a
            >
          </div>
        </div>

        <div class="box box5">
          <h4>수강신청/survey</h4>
          <div
            class="admin shadow mb pointer"
            @click="
              emp.moveAdmin(
                `http://admin.imooc.co.kr/rest/auth/login?kname=${emp.kname}&email=${emp.email}&is_intranet=Y`
              )
            "
          >
            <a>이러닝 수강신청</a>
          </div>
          <div
            class="admin shadow mb pointer"
            @click="
              emp.moveAdmin(
                `https://applyadmin.carrotglobal.com/users/carrot-login?email=${emp.email}&idx=${emp.idx}`
              )
            "
          >
            <a>글로벌 수강신청</a>
          </div>
          <div
            class="admin shadow mb pointer"
            @click="
              emp.moveAdmin(
                `https://apply.carrotenglish.net/rest/auth/login?kname=${emp.kname}&email=${emp.email}&rtn_url=`
              )
            "
          >
            <a>전화/화상 수강신청</a>
          </div>
          <div
            class="admin shadow mb pointer"
            @click="
              emp.moveAdmin(
                `http://carrotsurvey.com/rest/common/loginHQ?uid=${emp.email}&returnUrl=/admin/index/`
              )
            "
          >
            <a>CARROT Survey</a>
          </div>
          <div
            class="admin shadow mb pointer"
            @click="
              emp.moveAdmin(
                `https://integrated-sugang-admin.carrotapply.com/login?email=${emp.email}&idx=${emp.idx}`
              )
            "
          >
            <a>통합 수강신청</a>
          </div>
        </div>
        <div class="box box6">
          <h4>고객사전용사이트</h4>
          <div class="list site">
            <a
              href="https://hanwhaoceandodream.co.kr/home/index#"
              target="_blank"
              ><div class="check">랜딩표기</div>
              한화오션</a
            >
          </div>
          <!-- <div class="list site">
            <a href="http://dsmedodream.co.kr/home/index" target="_blank"
              ><div class="check">랜딩표기</div>
              대우조선해양</a
            >
          </div> -->
          <div class="list site">
            <a href="http://yeojuenglish.co.kr/home/index" target="_blank"
              ><div class="check">랜딩표기</div>
              여주시청</a
            >
          </div>
          <div class="list site">
            <a href="http://abc.hscity.go.kr/home/index" target="_blank">
              <div class="check">랜딩표기</div>
              화성시청</a
            >
          </div>
          <div class="list site">
            <!-- <a href="https://www.hscarrot.co.kr/home?idxLeSiteMain=1" target="_blank"> -->
            <a href="https://hscarrot.co.kr" target="_blank">
              <div class="check">랜딩표기</div>
              화성시청 (평생교육 플랫폼)</a
            >
          </div>
          <!-- <div class="list site">
            <a
              href="http://xn--299a9hp20bevghb650abhb60et8d.kr/"
              target="_blank"
              ><div class="check">랜딩표기</div>
              고양시청</a
            >
          </div>
          <div class="list site">
            <a href="http://www.anyangchinese.com/home/index" target="_blank"
              ><div class="check">랜딩표기</div>
              안양시청</a
            >
          </div> -->
          <div class="list site">
            <a href="http://seoincheoneng.co.kr/home/index" target="_blank"
              ><div class="check">랜딩표기</div>
              인천서구청</a
            >
          </div>
          <div class="list site">
            <a href="http://gunsancity.carrotenglish.co.kr/" target="_blank"
              ><div class="check">랜딩표기</div>
              군산시청</a
            >
          </div>

          <div class="list site">
            <a
              class="pointer"
              @click="
                emp.moveAdmin(
                  `https://life-admin.carrotapply.com/connectionHQ?email=${emp.email}&idx=${emp.idx}`
                )
              "
              target="_blank"
              ><div class="check">랜딩표기</div>
              평생교육 플랫폼 관리자</a
            >
          </div>

          <div class="admin shadow mb">
            <a
              href="http://adminb2b.daily-writing.co.kr/overview/"
              target="_blank"
              >DAILY WRITING (B2B)</a
            >
          </div>

          <div
            class="admin shadow mb pointer"
            @click="
              emp.moveAdmin(
                `http://b2b.carrotglobal.com/admin/connectionB2B.php?idemail=${emp.email}`
              )
            "
          >
            <a>B2B 통합사이트</a>
          </div>
        </div>
      </div>

      <!-- 3행 -->
      <div class="wrap">
        <div class="box box7">
          <h4>밝음</h4>
          <div class="list">
            <div class="site line list_b_r_1">
              <a href="http://bhappy.co.kr/" target="_blank"
                ><div class="check">랜딩표기</div>
                밝음심리센터</a
              >
            </div>
            <div
              class="admin list_b_r_2 line pointer"
              @click="
                emp.moveAdmin(
                  `http://admin.bhappy.co.kr/act/outlogin?email=${emp.email}`
                )
              "
            >
              <a>밝음 홈페이지 관리자</a>
            </div>
            <div class="admin list_b_r_3">
              <a href="http://www.happynetwork.info/login" target="_blank"
                >밝음 CRM</a
              >
            </div>
          </div>

          <div class="list">
            <div class="site line list_b_r_1">
              <a href="https://mybrightlife.co.kr" target="_blank"
                ><div class="check">랜딩표기</div>
                밝음생애센터</a
              >
            </div>
            <div
              class="site line list_b_r_2 line pointer"
              @click="emp.moveAdmin(`https://m.mybrightlife.co.kr`)"
            >
              <a
                ><div class="check">랜딩표기</div>
                밝음생애센터(모바일)</a
              >
            </div>
            <div
              class="admin list_b_r_2 line pointer"
              @click="emp.moveAdmin(`https://hq.mybrightlife.co.kr`)"
            >
              <a>관리자단</a>
            </div>

            <div class="admin list_b_r_3">
              <a href="https://tutor.mybrightlife.co.kr" target="_blank"
                >강사단</a
              >
            </div>
          </div>
        </div>
        <div class="box box8">
          <h4>캐럿컨텐츠</h4>
          <div
            class="admin shadow mb pointer"
            @click="
              emp.moveAdmin(
                `http://www.carrotenglish.com/_admin/connectionLMS.php?idemail=${emp.email}`
              )
            "
          >
            <a>LMS (old)</a>
          </div>
          <div
            class="admin shadow mb pointer"
            @click="
              emp.moveAdmin(
                `https://reuters.carrotenglish.com/login?uid=${emp.email}`
              )
            "
          >
            <a>LMS (new)</a>
          </div>
        </div>
        <div class="box box9">
          <h4>CARROT</h4>
          <div class="list site">
            <a href="https://carrotglobal.com/" target="_blank"
              ><div class="check">랜딩표기</div>
              CARROT HUB</a
            >
          </div>
          <div class="list site">
            <a href="https://carrotglobal.com/global" target="_blank"
              ><div class="check">랜딩표기</div>
              기업교육:글로벌 역량</a
            >
          </div>
          <div class="list site">
            <a href="http://biz.carrotenglish.com/callenglish" target="_blank"
              ><div class="check">랜딩표기</div>
              전화·화상외국어</a
            >
          </div>
          <div class="list site">
            <a href="http://biz.carrotenglish.com/edu-tech" target="_blank"
              ><div class="check">랜딩표기</div>
              온라인·AI과정</a
            >
          </div>
          <div class="list">
            <div class="site line list_b_r_1">
              <a href="https://carrotglobal.com/" target="_blank"
                ><div class="check">랜딩표기</div>
                CARROT Global</a
              >
            </div>
            <div class="admin list_b_r_3 pointer">
              <a href="https://carrotglobal.com/Admin/News" target="_blank"
                >보도자료 게시판 관리자</a
              >
            </div>
          </div>
          <div class="list site">
            <a href="https://www.carrotglobal.com/Introduce" target="_blank"
              ><div class="check">랜딩표기</div>
              회사 소개</a
            >
          </div>
        </div>
      </div>

      <!-- 4행 -->
      <div class="wrap">
        <div class="box box1">
          <h4>스피킹랩</h4>
          <div class="list">
            <div
              class="admin shadow mb pointer"
              @click="
                emp.moveAdmin(
                  `https://speaking2.carrotenglish.net/auth/${emp.base_convert(
                    emp.base_convert(emp.link_today, 13, 28),
                    28,
                    32
                  )}?kname=${emp.kname}&uidx=${emp.email}`
                )
              "
            >
              <a>스피킹랩 관리자</a>
            </div>

            <!-- <div class="admin shadow mb pointer" @click="emp.moveAdmin(`https://kr.object.ncloudstorage.com/speakinglab2/android_apk/speaking_lab_20220310.apk`)">
              <a>Android 앱 다운받기</a>
            </div> -->

            <div
              class="admin shadow mb pointer"
              @click="
                emp.moveAdmin(`https://carrotglobal.com/speakinglab/down`)
              "
            >
              <a>스피킹랩 모바일 다운로드</a>
            </div>

            <!-- <div class="admin shadow mb pointer" @click="emp.moveAdmin(`https://apps.apple.com/us/app/speakinglab/id6463774714`)">
              <a>IOS 앱 다운받기</a>
            </div> -->
          </div>
        </div>
        <div class="box box2">
          <h4>BNB</h4>
          <div class="list">
            <div class="site line list_b_r_1">
              <a href="https://bnb-admin.carrotglobal.com/down" target="_blank"
                ><div class="check">랜딩표기</div>
                BNB - 앱 다운로드
              </a>
            </div>
            <div
              class="admin list_b_r_2 line pointer"
              @click="
                emp.moveAdmin(
                  `https://bnb-admin.carrotglobal.com/?userId=${emp.email}`
                )
              "
            >
              <a>BNB 관리자</a>
            </div>
            <div
              class="admin list_b_r_3 pointer"
              @click="
                emp.moveAdmin(
                  `https://bnb-admin.carrotglobal.com/manager/login`
                )
              "
            >
              <a>BNB 담당자</a>
            </div>
          </div>
        </div>
        <div class="box box3">
          <h4>MangoTalk</h4>
          <div class="list">
            <div class="site line list_b_r_1">
              <a href="https://mangotalk.co.kr" target="_blank"
                ><div class="check">랜딩표기</div>
                MangoTalk
              </a>
            </div>
            <div
              class="admin list_b_r_1 line pointer"
              @click="emp.moveAdmin(`https://admin-tutor.mangotalk.co.kr`)"
            >
              <a>Tutor</a>
            </div>
            <div
              class="admin list_b_r_2 line pointer"
              @click="
                emp.moveAdmin(
                  `https://admin-hq.mangotalk.co.kr/login?email=${emp.email}&idx=${emp.idx}`
                )
              "
            >
              <a>HQ</a>
            </div>
            <div
              class="admin list_b_r_2 line pointer"
              @click="
                emp.moveAdmin(
                  `https://topic-admin.mangotalk.co.kr/act/outlogin?email=${emp.email}`
                )
              "
            >
              <a>교재관리</a>
            </div>
            <div
              class="admin list_b_r_3 line pointer"
              @click="emp.moveAdmin(`https://recruit.mangotalk.co.kr`)"
            >
              <a>강사채용</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * @작업시 규칙
 *  태그를 붙이는 경우 : 사용자단
 *  태그를 붙이지 않는 경우 : 관리자단, 강사단
 */
import { onMounted, reactive } from "vue";
import { useToast } from "vue-toastification";
import axios from "@/plugins/axios.js";
import { useStore } from "vuex";
export default {
  components: {},
  setup() {
    const toast = useToast();
    const store = useStore();
    const emp = reactive({
      idx: store.state.idx_staff,
      email: store.state.email,
      kname: store.state.kname,
      uid: "",
      upw: "",
      check: "N",
      list: [],
      listSubLast: [],
      link_today: "",
      doSearch: () => {
        axios.post("/rest/mypims/getEmpNumber").then((res) => {
          if (res.data.err == 0) {
            console.log(res.data);
            emp.list = res.data.listFirst;
            emp.listSubLast = res.data.listSubLast;
          } else {
            if (res.data.err_msg) toast.error(res.data.err_msg);
          }
        });
      },

      moveAdmin: (path) => {
        window.open(path, "_blank");
      },

      base_convert(number, frombase, tobase) {
        return parseInt(number + "", frombase | 0).toString(tobase | 0);
      },

      itsMeAdmin: () => {
        if (store.state.isItsMeAdmin == true) {
          window.open("http://admin.itsmego.com/list", "_blank");
        } else {
          alert(
            "해당 사이트의 열람이 제한되었습니다.\n컨텐츠플랫폼사업본부에 문의해 주세요."
          );
        }
      },
    });
    onMounted(() => {
      // Mounted
      emp.doSearch();

      let today = new Date();
      let year = today.getFullYear();
      let month = ("0" + (today.getMonth() + 1)).slice(-2);
      let day = ("0" + today.getDate()).slice(-2);
      emp.link_today = year + month + day;
    });

    return { emp };
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  background-color: #fafafa;
  font-weight: 400;
  h3 {
    font-size: 26px;
    font-weight: 700;
    color: #555555;
    text-align: center;
    padding-top: 58px;
    padding-bottom: 70px;
  }
}
.contents_wrap {
  width: 900px;
  margin: 0 auto;
}
.wrap {
  overflow: hidden;
  padding-bottom: 70px;
}
.box {
  width: 263px;
  float: left;
  margin-right: 55px;
  &.box1 {
    h4 {
      background-color: #67afde;
    }
    a:hover {
      color: #67afde;
    }
  }
  &.box2 {
    h4 {
      background-color: #f29f4c;
    }
    .site {
      .check {
        background: #f29f4c;
      }
    }
    a:hover {
      color: #f29f4c;
    }
  }
  &.box3 {
    margin-right: 0;
    h4 {
      background-color: #ee9fe7;
    }
    .site {
      .check {
        background: #ee9fe7;
      }
    }
    a:hover {
      color: #ee9fe7;
    }
  }
  &.box4 {
    h4 {
      background-color: #738afe;
    }
    .site {
      .check {
        background: #738afe;
      }
    }
    a:hover {
      color: #738afe;
    }
  }
  &.box5 {
    h4 {
      background-color: #fe8f73;
    }
    a:hover {
      color: #fe8f73;
    }
  }
  &.box6 {
    margin-right: 0;
    h4 {
      background-color: #5182e3;
    }
    .site {
      .check {
        background: #5182e3;
      }
    }
    a:hover {
      color: #5182e3;
    }
  }
  &.box7 {
    h4 {
      background-color: #f2be4c;
    }
    .site {
      .check {
        background: #f2be4c;
      }
    }
    a:hover {
      color: #f2be4c;
    }
  }
  &.box8 {
    h4 {
      background-color: #2fc4ee;
    }
    a:hover {
      color: #2fc4ee;
    }
  }
  &.box9 {
    margin-right: 0;
    h4 {
      background-color: #67deb1;
    }
    .site {
      .check {
        background: #67deb1;
      }
    }
    a:hover {
      color: #46c594;
    }
  }
  h4 {
    font-size: 18px;
    color: #fff;
    background-color: #67afde;
    padding: 6px 24px;
    border-radius: 19px 19px 19px 0;
    display: inline-block;
    margin-bottom: 13px;
  }
  .list {
    box-shadow: 0px 0px 8px rgba(158, 158, 158, 0.14);
    border-radius: 0 14px 14px 14px;
    margin-bottom: 16px;
  }
  .site {
    background-color: #fff;
    font-size: 18px;
    font-weight: 500;
    position: relative;
    .check {
      text-indent: -9999px;
      background: #67afde;
      width: 3px;
      height: 22px;
      position: absolute;
      left: 0;
    }
  }
  .admin {
    background-color: #fff;
    font-size: 16px;
    border-radius: 0 14px 14px 14px;
  }
  .line {
    border-bottom: #e5e5e5 1px solid;
  }
  .list_b_r_1 {
    border-radius: 0 14px 0 0;
  }
  .list_b_r_2 {
    border-radius: 0 0 0;
  }
  .list_b_r_3 {
    border-radius: 0 0 14px 14px;
  }
  .shadow {
    box-shadow: 0px 0px 8px rgba(158, 158, 158, 0.14);
  }
  .mb {
    margin-bottom: 16px;
  }
  a {
    display: block;
    padding: 16px 16px 16px 25px;
  }
}

.pointer {
  cursor: pointer;
}
</style>
